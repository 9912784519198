import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { useDispatch } from 'react-redux'
import { Row, Col } from 'reactstrap'
import { window, exists } from 'browser-monads'
import exitIntent from 'exit-intent'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import PageHelmet from '../components/PageHelmet'
import AptitudeCard from '../components/AptitudeCard'
import ExitIntentPopup from '../components/ExitIntentPopup'
import { setHelloBarContent } from '../store/features/aptitudeTest/aptitudeTestSlice'

const AptitudeTestsHomePage = ({ data, location }) => {
  const dispatch = useDispatch()
  const tests = data.tests.edges || []
  const [exitIntentPopupShown, setExitIntentPopupShown] = useState(false)
  const [pageIsScrolled, setPageIsScrolled] = useState(false)

  const maxCardsPerRow = 3

  useEffect(() => {
    dispatch(
      setHelloBarContent({
        url: 'https://tests.wikijob.co.uk/hello-bar',
        buttonText: `Take a test!`,
        text: `Don't lose out on that job. Practice aptitude tests today.`,
      }),
    )
  }, [dispatch])

  const cards = tests.map(({ node: test }) => (
    <AptitudeCard
      key={test.id}
      image={`${test.icon.url}?width=${942 / maxCardsPerRow}`}
      title={test.name}
      description={test.description}
      url={`/aptitude-test/${test.slug}`}
      limit={test.timeLimit / 60}
    />
  ))

  const totalRows = tests.length / maxCardsPerRow

  const rows = []

  let cardIndex = 0
  for (let row = 0; row < totalRows; ++row) {
    const lastCardIndex = Math.min((row + 1) * maxCardsPerRow, cards.length)
    const columns = []
    for (; cardIndex < lastCardIndex; ++cardIndex) {
      columns.push(
        <Col md={12 / maxCardsPerRow} id={cardIndex} key={cardIndex}>
          {cards[cardIndex]}
        </Col>,
      )
    }
    rows.push(columns)
  }

  if (exists(window)) {
    useScrollPosition(({ prevPos, currPos }) => {
      if (
        prevPos.y !== 0 &&
        prevPos.y !== currPos.y &&
        location.pathname.includes('aptitude-tests')
      ) {
        if (!pageIsScrolled) {
          exitIntent({
            threshold: 18,
            maxDisplays: 1,
            eventThrottle: 500,
            onExitIntent: () => {
              setExitIntentPopupShown(true)
            },
          })
          setPageIsScrolled(true)
        }
      }
    })
  }

  if (exists(window)) {
    window.dataLayer && window.dataLayer.push({ event: 'componentDidMount' })
  }
  return (
    <>
      <PageHelmet
        {...{
          title: `${tests.length} Free Practice Aptitude Tests: Take A Free Test`,
          description: `${tests.length} free practice aptitude tests covering all the major types of psychometric test employers use.`,
          url: `https://www.wikijob.co.uk${location.pathname}`,
        }}
      />
      <style dangerouslySetInnerHTML={{ __html: '' }} />
      <div className="section aptitude-section pt-0 pb-5">
        <div className="container">
          <div className="text-center">
            <h1 style={{ paddingTop: 0, color: '#ff8000' }}>
              Free Practice Aptitude Tests
            </h1>
            <p>
              Take our {tests.length} free practice aptitude tests and prepare
              yourself for the most common types of psychometric tests used by
              employers in many different job application processes.
            </p>
            <p>
              All tests have 10 questions and a strict time limit. Once you have
              completed a test you will be able to check your answers and read a
              full explanation.
            </p>
          </div>
          {rows.map((row, i) => (
            <Row className="pt-5" key={i}>
              {row.map((column) => column)}
            </Row>
          ))}
          <Row>
            <Col md={4}>
              <h2>What is an aptitude test?</h2>
              <p>
                An <strong>aptitude test</strong> is an assessment used by an
                employer to determine the suitability of a candidate for
                employment. These types of tests are becoming increasingly
                common as part of the recruitment process, especially for large
                corporate employers.
              </p>
              <p>
                Aptitude tests are popular with employers because they can be
                used to assess large numbers of job applicants relatively
                quickly.
              </p>
              <br />
              <a
                className="btn btn-orange btn-block"
                href="https://tests.wikijob.co.uk/practice-tests?tid1=aptitude-tests-home&tid2=button"
                target="_blank"
                rel="noreferrer nofollow sponsored"
                style={{ borderRadius: '10px', textTransform: 'uppercase' }}
              >
                Start Practicing
              </a>
            </Col>
            <Col md={4}>
              <h2>What are the different types of aptitude test?</h2>
              <p>
                There are many different types of aptitude tests. Employers
                sometimes use a combination of test types to assess candidates,
                but commonly pick test types that assess the most relevant
                skills for the job they are hiring for. Aptitude tests can be
                broadly classified as 'Verbal’ or ‘Non-Verbal’.
              </p>
              <p>
                <strong>Verbal tests</strong> will contain questions with
                passages of text and questions relating to verbal comprehension
                and ability.
              </p>
              <p>
                <strong>Non-Verbal tests</strong> often contain questions with
                numbers, shapes or patterns and require a test taker to assess
                this data and make accurate observations under time pressure.{' '}
              </p>
              <br />
              <a
                className="btn btn-orange btn-block"
                href="https://tests.wikijob.co.uk/practice-tests?tid1=aptitude-tests-home&tid2=button"
                target="_blank"
                rel="noreferrer nofollow sponsored"
                style={{ borderRadius: '10px', textTransform: 'uppercase' }}
              >
                Start Practicing
              </a>
            </Col>
            <Col md={4}>
              <h2>How do I prepare for an aptitude test?</h2>
              <p>
                Aptitude tests are designed to be challenging. If you want to
                pass your aptitude tests, you will need to perform better than
                most other candidates. To do so, you will need to familiarise
                yourself with the type of aptitude tests you will be taking. A
                great way of doing this is to take practice tests online and
                review your answers when you complete a test.
              </p>
              <p>
                The{' '}
                <strong>
                  {tests.length} free practice aptitude tests provided
                </strong>{' '}
                on this page are a great place to start. If you require more
                practice, we recommend{' '}
                <a
                  href="https://tests.wikijob.co.uk/practice"
                  rel="sponsored nofollow"
                >
                  JobTestPrep
                </a>{' '}
                as a comprehensive resource of practice test material.
              </p>
              <br />
              <a
                className="btn btn-orange btn-block"
                href="https://tests.wikijob.co.uk/practice-tests?tid1=aptitude-tests-home&tid2=button"
                target="_blank"
                rel="noreferrer nofollow sponsored"
                style={{ borderRadius: '10px', textTransform: 'uppercase' }}
              >
                JobTestPrep Tests
              </a>
            </Col>
          </Row>
          <div className="row">
            <div className="content_hint" />
          </div>
        </div>
      </div>

      {pageIsScrolled &&
        exitIntentPopupShown &&
        location.pathname.includes('aptitude-tests') && (
          <ExitIntentPopup
            pathname={location.pathname}
            closePopup={() =>
              exitIntentPopupShown && setExitIntentPopupShown(false)
            }
          />
        )}
    </>
  )
}

export const query = graphql`
  {
    tests: allDatoCmsTest {
      edges {
        node {
          id
          name
          slug
          description
          passPercentage
          timeLimit
          icon {
            url
            title
          }
          questions {
            id
          }
        }
      }
    }
  }
`

export default AptitudeTestsHomePage
