import React from 'react'
import { Link } from 'gatsby'

export default function AptitudeCard(props) {
  return (
    <div className="pb-4">
      <div className="card shadow" style={{ borderRadius: '10px' }}>
        <Link to={props.url}>
          <div
            className="text-center card-header"
            style={{
              height: '150px',
              background: `url(${props.image})`,
              backgroundSize: 'cover',
            }}
          ></div>
        </Link>
        <div className="text-center card-body" style={{ height: '280px' }}>
          <h3
            style={{ fontSize: '1.25em', paddingTop: 0, fontFamily: 'PT Sans' }}
          >
            {props.title}
          </h3>
          <p style={{ height: '150px' }}>{props.description}</p>
          <div className="row">
            <div className="col-4">
              <small>Questions</small>
              <p>
                <strong>{props.questions ? props.questions : 10}</strong>
              </p>
            </div>
            <div className="col-4">
              <small>Pass %</small>
              <p>
                <strong>{props.pass ? props.pass : 80}%</strong>
              </p>
            </div>
            <div className="col-4">
              <small>Time Limit</small>
              <p>
                <strong>{props.limit ? props.limit : 5} min</strong>
              </p>
            </div>
          </div>
        </div>
        <Link style={{ color: 'white' }} to={props.url}>
          <div
            style={{ fontSize: '1.1em' }}
            className="card-footer btn-orange text-center"
          >
            <strong>Take Free Test</strong>
          </div>
        </Link>
      </div>
    </div>
  );
}
